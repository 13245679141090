import React from 'react';
import './style.scss';
import logo from 'assets/imgs/footer_logo.svg';
import footer_dia_chi from 'assets/imgs/footer_dia_chi.svg';
import footer_dien_thoai from 'assets/imgs/footer_dien_thoai.svg';
import footer_fax from 'assets/imgs/footer_fax.svg';
import footer_email from 'assets/imgs/footer_email.svg';
import footer_giay_chung_nhan from 'assets/imgs/footer_giay_chung_nhan.svg';
import footer_people from 'assets/imgs/footer_people.svg';
import { Link } from 'react-router-dom';
import { POLICY, PRIVATE, TERM, PERSONAL_POLICY } from 'global/routes';

const Footer = () => {

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__top">
          <img src={logo} alt="logo" className="footer__logo" />
          <div>
            <Link to={POLICY}>Chính sách dịch vụ</Link>
            <Link to={PRIVATE}>Chính sách bảo mật</Link>
            <Link to={TERM}>Điều khoản sử dụng</Link>
            <Link to={PERSONAL_POLICY}>Chính sách bảo vệ dữ liệu cá nhân</Link>
          </div>
        </div>
        <hr />

        <h1>
          TẬP ĐOÀN BƯU CHÍNH VIỄN THÔNG VIỆT NAM (VNPT)
        </h1>

        <div className="footer__item-wrapper">
          <div className="footer__item">
            <img src={footer_dia_chi} alt="logo" className="footer__item-img" />
            <div>
              <div className="footer__title">Cơ quan quản lý trang thông tin điện tử</div>
              <div className="footer__des">TỔNG CÔNG TY TRUYỀN THÔNG VNPT-MEDIA</div>
            </div>
          </div>

          <div className="footer__item">
            <img src={footer_giay_chung_nhan} alt="logo" className="footer__item-img mr-4" />
            <div>
              <div className="footer__title">Địa chỉ</div>
              <div className="footer__des">Số 57A phố Huỳnh Thúc Kháng, Phường Láng Hạ, Quận Đống Đa, Thành phố Hà Nội, Việt Nam.</div>
            </div>
          </div>

          <div className="footer__item">
            <img src={footer_dien_thoai} alt="logo" className="footer__item-img mr-4" />
            <div>
              <div className="footer__title">Số ĐKKD</div>
              <div className="footer__des">0106873188 do Sở KH-DT cấp ngày 12/06/2015 </div>
            </div>
          </div>

          <div className="footer__item">
            <img src={footer_people} alt="logo" className="footer__item-img mr-4" />
            <div>
              <div className="footer__title">Giấy chứng nhận cung cấp dịch vụ nội dung thông tin trên mạng viễn thông di động số</div>
              <div className="footer__des">19/GCN - DĐ do Cục Phát thanh Truyền hình và Thông tin Điện tử cấp ngày 12/1/2017.</div>
            </div>
          </div>

          <div className="footer__item">
            <img src={footer_fax} alt="logo" className="footer__item-img mr-4" />
            <div>
              <div className="footer__title">THÔNG TIN LIÊN HỆ</div>
              <div className="footer__des">Email: vnptmedia@vnpt.vn - Điện thoại: 0437722728 - Fax: 0437722733</div>
            </div>
          </div>

          <div className="footer__item">
            <img src={footer_email} alt="logo" className="footer__item-img mr-4" />
            <div>
              <div className="footer__title">Chịu trách nhiệm quản lý nội dung</div>
              <div className="footer__des">Ông Vũ Trường Giang - TGĐ VNPT Media</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
